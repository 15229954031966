    /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
    /* Core CSS required for Ionic components to work properly */
    
    @import "~@ionic/angular/css/core.css";
    /* Basic CSS for apps built with Ionic */
    
    @import "~@ionic/angular/css/normalize.css";
    @import "~@ionic/angular/css/structure.css";
    @import "~@ionic/angular/css/typography.css";
    @import '~@ionic/angular/css/display.css';
    /* Optional CSS utils that can be commented out */
    
    @import "~@ionic/angular/css/padding.css";
    @import "~@ionic/angular/css/float-elements.css";
    @import "~@ionic/angular/css/text-alignment.css";
    @import "~@ionic/angular/css/text-transformation.css";
    @import "~@ionic/angular/css/flex-utils.css";
    @import "./theme/variables.scss";
    @import "./theme/custom-global-style.scss";
    @import '~intro.js/introjs.css';
    @import "swiper/element/css/navigation";
    @media screen and (min-width: 800px) {
        // this css will only be used when the screen size is min 800px
        .view {
            width: 400px; // typical phone css width
            // height: 100vh;
            margin: 0 auto;
        }
    }
    
    // @font-face {
    //     font-family: 'Poppins'; //This is what we are going to call
    //     src: url('/assets/fonts/Poppins-Light');
    // }
    .file-loader-modal .modal-wrapper {
        // height: 20rem;
        // width: 15rem;
        border-radius: 1rem
    }
    
    .file-loader-modal,
    .signup-modal,
    .my-custom-class,
    .verification-modal,
    .audio-recorder-modal,
    .small-modal,
    .showFromWallModal,
    .showConnectionsPageModal,
    .shareFromWallModal,
    .group-options-popover {
        backdrop-filter: blur(5px);
    }
    
    .signup-modal {
        --border-radius: 10px;
    }
    
    .my-custom-class {
        --border-radius: 10px;
        // background: rgba(0, 0, 0, 0.5);
    }
    
    .my-custom-class>.modal-wrapper,
    .verification-modal>.modal-wrapper,
    .signup-modal>.modal-wrapper {
        --border-radius: 10px;
        height: 600px;
    }
    
    .about-policy-modal>.modal-wrapper {
        --width: 100% !important;
        --height: 100% !important;
    }
    
    .verification-toast {
        --background: var(--ion-color-light);
        --color: var(--ion-color-2);
    }
    
    ion-backdrop {
        background: black;
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.85) !important;
        -webkit-mask-image: linear-gradient(to top, black 10%, transparent 80%) !important;
        mask-image: linear-gradient(to top, black 10%, transparent 80%);
    }
    
    .audio-recorder-modal {
        --max-height: 400px;
        --width: 300px;
    }
    
    button.action-sheet-button.ion-activatable.ion-focusable.highlight-red.sc-ion-action-sheet-ios {
        // background: var(--ion-color-primary);
        // color: var(--ion-color-light);
        background: wheat;
        color: brown;
    }
    
    ion-refresher {
        z-index: 9 !important;
    }
    
    @media screen and (min-width: 700px) {
        .post-hcp-signup-upgrade-alert>.alert-wrapper {
            --max-width: 650px;
        }
    }
    
    @media screen and (max-width: 700px) {
        .post-hcp-signup-upgrade-alert>.alert-wrapper {
            --max-width: 350px;
        }
    }
    
    .InvitationCodeINfo-input {
        width: 97%;
    }
    
    .InvitationCodeINfo {
        position: absolute;
        right: 0px;
        bottom: 0px;
        ion-icon {
            font-size: 20px;
        }
    }
    
    .inviteCodeMsg {
        .alert-head .alert-sub-title {
            font-size: 12pt;
            color: #000000;
            font-weight: 600;
        }
        .alert-message {
            text-align: start;
            font-size: 10.5pt;
            line-height: 1.2;
            white-space: break-spaces;
        }
    }
    
    .message-wrapper-right .chatLink {
        color: white;
    }
    
    .see_more_lest_txt {
        cursor: pointer;
    }
    
    .edit-case-update {
        // --min-height: 500px !important;
        --min-width: 300px !important;
    }
    
    textarea.alert-input.sc-ion-alert-md,
    textarea.alert-input.sc-ion-alert-ios {
        min-height: 300px;
        resize: none;
    }
    
    // HL-484 - clickable link 
    .clickable_link {
        color: #3880ff !important;
        cursor: pointer;
    }
    
    .create-post-media-page {
        background-color: black;
        --background: black;
        swiper-container {
            height: 100% !important;
        }
        // swiper-slide *{
        //     // width: auto;
        //     // max-width: 100%;
        //     // height: auto;
        //     // max-height: 100%;
        //     // margin: 0 auto;
        // }
        swiper-container::part(button-next) {
            padding: 1rem;
            width: 30px;
            height: 30px;
            background: rgb(89 92 97 / 72%);
            border-radius: 70px;
            border: 1px solid rgba(255, 255, 255, 0.125);
            color: white;
        }
        swiper-container::part(button-prev) {
            padding: 1rem;
            width: 30px;
            height: 30px;
            background: rgb(89 92 97 / 72%);
            border-radius: 70px;
            border: 1px solid rgba(255, 255, 255, 0.125);
            color: white;
        }
        swiper-container::part(pagination) {
            bottom: 50px;
            left: 0;
            width: 100%;
        }
        swiper-container::part(bullet) {
            background: #ffffff;
        }
        swiper-container::part(bullet-active) {
            background: #003B57;
        }
        swiper-slide {
            align-content: center;
            text-align: center;
        }
        swiper-slide img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            margin: 0 auto;
            padding: 5px 5px;
        }
    }
    
    .display-media-page {
        background-color: black;
        --background: black;
        swiper-container::part(button-next) {
            padding: 1rem;
            width: 30px;
            height: 30px;
            background: rgb(89 92 97 / 72%);
            border-radius: 70px;
            border: 1px solid rgba(255, 255, 255, 0.125);
            color: white;
        }
        swiper-container::part(button-prev) {
            padding: 1rem;
            width: 30px;
            height: 30px;
            background: rgb(89 92 97 / 72%);
            border-radius: 70px;
            border: 1px solid rgba(255, 255, 255, 0.125);
            color: white;
        }
        swiper-container::part(pagination) {
            bottom: 50px;
            left: 0;
            width: 100%;
        }
        swiper-container::part(bullet) {
            background: #ffffff;
        }
        swiper-container::part(bullet-active) {
            background: #003B57;
        }
        swiper-slide {
            align-content: center;
            text-align: center;
        }
        swiper-slide img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            margin: 0 auto;
            padding: 5px 5px;
        }
    }
    
    .wall-media-slider {
        background-color: black;
        --background: black;
        swiper-container::part(button-next) {
            padding: 8px;
            width: 25px;
            height: 25px;
            background: rgb(89 92 97 / 72%);
            border-radius: 70px;
            border: 1px solid rgba(255, 255, 255, 0.125);
            color: white;
        }
        swiper-container::part(button-prev) {
            padding: 8px;
            width: 25px;
            height: 25px;
            background: rgb(89 92 97 / 72%);
            border-radius: 70px;
            border: 1px solid rgba(255, 255, 255, 0.125);
            color: white;
        }
        swiper-container::part(pagination) {
            bottom: 10px;
            left: 0;
            width: 100%;
        }
        swiper-container::part(bullet) {
            background: #cccccc;
        }
        swiper-container::part(bullet-active) {
            background: #003B57;
        }
        swiper-slide {
            align-content: center;
            text-align: center;
            max-height: 20rem;
        }
        swiper-slide img {
            width: auto;
            max-width: 100%;
            height: auto;
            // max-height: 100%;
            margin: 0 auto;
            align-content: center;
            text-align: center;
            text-align: -webkit-center;
        }
    }
    
    .pdf-details {
        // position: absolute;
        width: 100%;
        background: #0000006b;
        z-index: 1;
        padding: 4px 25px;
        color: white;
        font-size: 13px;
        top: 0;
        ion-icon {
            color: #ffffff !important;
            position: initial !important;
            background-color: transparent !important;
            border: 0 !important;
            transform: none !important;
            font-size: 20px !important;
            padding: 0 !important;
        }
        .page-counts {
            font-size: 13px;
            color: #ffffff;
            vertical-align: super;
            padding-left: 5px;
        }
        img {
            width: 25px;
        }
    }
    
    .pdfoptions {
        position: absolute;
        bottom: 6rem;
        font-size: 31px;
        color: #ffffff;
        cursor: pointer;
        .zoom-option {
            width: 3rem;
            height: 2rem;
            display: inline-block;
            background: #003b57;
            align-content: center;
            margin: 2px;
            border-radius: 8px;
            padding-top: 7px;
            span {
                font-size: 25px;
                vertical-align: top;
            }
        }
        .download-option {
            width: 50px;
            height: 50px;
            display: inline-block;
            background: #003b57;
            align-content: center;
            margin: 2px;
            border-radius: 30px;
            padding-top: 7px;
        }
        // span{
        //     width: 50px;
        //     height: 50px;
        //     display: inline-block;
        //     background: #003b57;
        //     align-content: center;
        //     margin: 2px;
        //     border-radius: 30px;
        //     padding-top: 7px;
        // }
        ion-icon {
            width: 30px;
            height: 30px;
        }
    }
    
    // .media-post-list-display{
    .media-post-list-display-pdf .ng2-pdf-viewer-container {
        overflow: hidden !important;
    }
    
    // }
    .pdf-view-modal {
        padding-top: env(safe-area-inset-top);
    }
    
    .media-counter {
        position: absolute;
        right: 0;
        top: 3px;
        z-index: 999;
        font-size: 14px;
        color: #ffffff;
        background: #0000006b;
        padding: 3px 15px;
        border-radius: 10px;
    }
    
    .zoompdficon {
        font-size: 20px;
    }
    
    a.chatLink {
        display: block;
        word-wrap: break-word;
        max-width: 100%;
        margin-bottom: 10px;
    }
    
    audio {
        zoom: 1.1;
    }
    
    .oauth-comp-wrapper {
        border: 2px solid var(--ion-color-medium);
        border-radius: 1rem;
        padding: 1rem
    }