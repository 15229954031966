* {
    user-select: text;
    // color: rgb(26, 26, 26);
    // font-family: Poppins !important;
}

// ion-content {
//     // --background: url(../assets/images/bekir-donmez-eofm5R5f9Kw-unsplash.jpg) 0 0/100% 100%;
//     // --background: #e2e1b5;
// }
.glb-border-style {
    // border: 1px solid#d4d8db;
    box-shadow: 0 0 1px grey;
    // box-shadow: 0 0 2px black;
    // border-radius: var(--size-1);
}

.glb-padding-0--positioning {
    padding: 0px !important;
}

.glb-padding-8--positioning {
    padding: 8px !important;
}

.glb-padding-4-0--positioning {
    padding: 4px 0px !important;
}

.glb-padding-12-0--positioning {
    padding: 12px 0px !important;
}

.glb-padding-top-8--positioning {
    padding-top: 8px !important;
}

.glb-align-items--center {
    align-items: center !important;
}

.glb-align-center--positioning {
    justify-content: center;
}

.glb-float-right--positioning {
    float: right !important;
}

.glb-alignment--center {
    text-align: center;
}

.glb-alignment--justify {
    text-align: justify;
}

.glb-align-self-end {
    align-self: flex-end;
}

.glb-alignment--left {
    text-align: left !important;
}

.glb-alignment--right {
    text-align: right !important;
}

.glb-alignment--right-important {
    text-align: right !important;
}

.glb-display-flex--positioning {
    display: flex;
}

.glb-header-button-transparent {
    background-color: transparent;
}

.glb-text-header {
    font-weight: bold;
    text-align: center;
    font-size: 14pt;
}

.glb-header-color {
    color: white;
}

.glb-header-icon-size {
    font-size: 20pt;
}

.glb-border-3-solid--grey {
    border: 3px solid #d6d6d6;
}

.glb-color--grey {
    color: grey !important;
}

.glb-color--blue {
    color: blue;
}

.glb-border-radius-20-px--styling {
    border-radius: 20px;
}

.glb-font-14--typography {
    font-size: 14px;
}

.g-font-15 {
    font-size: 15px;
}

.glb-margin-top-75--positioning {
    margin-top: 75px;
}

.glb-margin-top-8-percent--positioning {
    margin-top: 8% !important;
}

.m-top-2 {
    margin-top: 2rem;
}

.m-top-3 {
    margin-top: 3rem;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

.glb-text-sm {
    font-size: var(--size-0_75);
}

.glb-profile-img {
    width: calc(var(--size-5) + var(--size-2));
    border-radius: 50% !important;
}

.glb-upload-btn {
    position: absolute !important;
}

.glb-mandatory {
    color: var(--ion-color-danger);
}

.ion-height-full {
    height: 100% !important;
}

.ion-width-full {
    width: 100% !important;
}

ion-searchbar {
    --background: var(--ion-color-primary-tint-light) !important;
    --border-radius: 2rem !important;
}

.glb-row {
    display: flex;
}

.glb-col {
    display: flex;
    flex-direction: column;
}

.glb-gap-1 {
    gap: 1rem;
}

.g-text-cap {
    text-transform: capitalize;
}

.g-text-upper {
    text-transform: uppercase;
}

.g-text-lower {
    text-transform: lowercase;
}

.white-space-normal,
.alert-checkbox-label {
    white-space: pre-wrap !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.white-space-prewrap {
    white-space: pre-wrap !important;
}

.zoom-1-2 {
    zoom: 1.2 !important;
}

.zoom-0-65 {
    zoom: 0.64 !important;
}

.zoom-0-70 {
    zoom: 0.70 !important;
}

.zoom-0-75 {
    zoom: 0.75 !important;
}

.zoom-0-85 {
    zoom: 0.85 !important;
}

.zoom-0-55 {
    zoom: 0.54 !important;
}

.zoom-0-5 {
    zoom: 0.5 !important;
}

.divider {
    background: var(--ion-color-medium-tint-2) !important;
}

.divider-light {
    background: var(--ion-color-primary-tint-light) !important;
}

// .g-b-radius-1 {
//     // border-radius: 1rem !important;
// }
.dot {
    width: 0.3rem;
    height: 0.3rem;
    background-color: var(--ion-color-medium);
    margin: 0 1rem;
    border-radius: 50%;
}

.red {
    border: 1px solid red;
}

.green {
    border: 1px solid green;
}

.big-avatar {
    width: 105px;
    height: 105px;
    margin: 11px auto;
    border-radius: 50%;
}

.small-avatar {
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
}

.glb-provider-details--styling {
    background-color: rgb(60, 142, 219);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 4px;
}

.glb-height-70px--positioning {
    height: 70px;
}

.glb-right-20px--positioning {
    right: 20px;
}

.glb-top--0px--positioning {
    top: 0px;
}

.glb-width-140-px--sizing {
    width: 140px;
}

.glb-border-radius-35-px--styling {
    --border-radius: 35px;
}

.glb-object-fit-cover--sizing {
    object-fit: cover;
}

.glb-width-20--sizing {
    width: 20%;
}

.glb-min-width-140px--sizing {
    min-width: 140px;
}

.glb-height-100--positioning {
    height: 100%;
}

.glb-display-col {
    flex-direction: column !important;
}

.glb-height-50--positioning {
    height: 50%;
}

.glb-width-100--sizing {
    width: 100%;
}

.glb-white-space-no-wrap--styling {
    white-space: nowrap !important;
}

.glb-justify-content--space-between {
    justify-content: space-between;
}

.glb-text-overflow-ellipsis--styling {
    text-overflow: ellipsis !important;
}

.glb-width-100-px--sizing {
    width: 100px;
}

.glb-margin-auto--positioning {
    margin: auto !important;
}

.glb-display-block--positioning {
    display: block;
}

.glb-box-shadow-none {
    box-shadow: none;
}

.glb-border-radius-50-percent--styling {
    border-radius: 50%;
}

.glb-display-grid--positioning {
    display: grid;
}

.glb-place-items--center {
    place-items: center;
}

.glb-display-inline-block--positioning {
    display: inline-block;
}

.glb-position--relative {
    position: relative;
}

.glb-position--absolute {
    position: absolute;
}

.glb-height-3-point-9em--positioning {
    height: 3.9em;
}

.glb-height-5-point-2em--positioning {
    height: 5.2em;
}

.glb-margin-3px--positioning {
    margin: 3px;
}

.glb-margin-left-10-percent--positioning {
    margin-left: 10%;
}

.glb-margin-left-16--positioning {
    margin-left: 16px;
}

.glb-overflow-x-auto--styling {
    overflow-x: auto !important;
}

.glb-background-color--white {
    --background: var(--ion-color-medium-contrast);
}

.glb-display-row {
    flex-direction: row;
}

.glb-display-col {
    flex-direction: column !important;
}

.glb_header_side_sizing {
    width: 20%;
}

.g-padding-2 {
    padding: 2px !important;
}

.g-padding-4 {
    padding: 4px !important;
}

.g-padding-12 {
    padding: 12px !important;
}

.g-padding-16 {
    padding: 16px !important;
}

.g-padding-24 {
    padding: 24px !important;
}

.g-margin-4 {
    margin: 4px !important;
}

.glb-font-18--typography {
    font-size: 18px;
}

.glb-font-19--typography {
    font-size: 18px;
}

.glb-font-13--typography {
    font-size: 13px;
}

.glb-font-16--typography {
    font-size: 16px;
}

.glb-margin-top-8--positioning {
    margin-top: 8px;
}

.glb-margin-top-12--positioning {
    margin-top: 12px;
}

.glb-margin-top-14--positioning {
    margin-top: 14px;
}

.glb-margin-top-40--positioning {
    margin-top: 40px;
}

.g-padding-8 {
    padding: 8px !important;
}

.g-padding-1rem {
    padding: 1rem;
}

.glb-padding-left-10--positioning {
    padding-left: 10px;
}

.tab-selected {
    background: var(--ion-color-primary-tint-light);
    border-radius: 1rem;
}

ion-button {
    --background-hover-opacity: 0.3;
    text-transform: capitalize;
}

.glb-display-hidden--positioning {
    display: none;
}

.alert-radio-label.sc-ion-alert-ios {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: 13px;
    padding-inline-start: 13px;
    -webkit-padding-end: 13px;
    padding-inline-end: 13px;
    white-space: pre-wrap;
}

.g-color-primary {
    background-color: var(--ion-color-primary-custom) !important;
}

.g-color-secondary {
    background-color: var(--ion-color-secondary-custom) !important;
}

.g-color-secondary-light {
    background-color: var(--ion-color-secondary-custom-light) !important;
}

.g-color-regular {
    background-color: var(--ion-color-regular-custom) !important;
}

.g-color-regular-white {
    background-color: hsl(0, 0%, 100%) !important;
}

.glb-hr--styling {
    width: 100%;
    background-color: var(--ion-color-medium-tint-2);
    size: 3px;
    margin: 1px;
}

.g-color-open {
    color: green;
}

.g-color-private {
    color: #fdcb6e;
}

.g-color-secret {
    color: #e17055;
}

.g-color-provider {
    color: #3880ff;
}

.glb-color--387ef5 {
    color: #387ef5;
}

.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios,
.g-ws {
    white-space: normal !important;
}

.glb-search-bar-shadow--styling {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.glb-padding-left-0--positioning {
    padding-left: 0px;
}

.glb-padding-left-16px--positioning {
    padding-left: 16px;
}

.glb-margin-top-0--positioning {
    margin-top: 0px;
}

.glb-margin-top--5px--positioning {
    margin-top: -5px !important;
}

.glb-height-30px-sizing {
    height: 30px;
}

.glb-width-auto--sizing {
    width: auto;
}

.glb-overflow-hidden--styling {
    overflow: hidden !important;
}

.glb-color--black {
    color: black;
}

.glb-font-15--typography {
    font-size: 15px;
}

.glb-margin-top-2--positioning {
    margin-top: 2px;
}

.glb-margin-top-4--positioning {
    margin-top: 4px;
}

.glb-margin-top-6--positioning {
    margin-top: 6px;
}

.glb-width-85px-sizing {
    height: 85px;
}

.glb-left-4-percent {
    left: 4%;
}

.glb-background-lightTint {
    // --background: #f2f2f2;
    background: var(--ion-color-primary-tint-light);
}

.g-profile-icon {
    &-2 {
        font-size: 2rem;
        color: var(--ion-color-secondary-custom);
    }

    &-3 {
        font-size: 3rem;
        color: var(--ion-color-secondary-custom);
    }

    &-3-header {
        font-size: 3rem;
        color: white;
    }

    &-4 {
        font-size: 4rem;
        color: var(--ion-color-secondary-custom);
    }

    &-8 {
        font-size: 8rem;
        color: var(--ion-color-secondary-custom);
    }
}

.shareFromWallModal {
    background: rgba(0, 0, 0, 0.5) !important;
    padding: 13vh 0vw !important;

    .modal-wrapper {
        border-radius: 20px;
    }
}

.showConnectionsPageModal {
    padding: 13vh 0vw !important;

    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.left,
.right {
    height: max-content;
    color: white;
    font-size: 2rem;
    position: absolute;
    top: 50%;
    z-index: 100;
    border-radius: 50%;
    backdrop-filter: blur(16px) saturate(180%);
    padding: 0.5rem 0.5rem 0 0.5rem;
    ;
    background-color: rgba(89, 92, 97, 0.55);
    border: 1px solid rgba(255, 255, 255, 0.125);
    cursor: pointer;
}

.left {
    left: 1rem;
}

.right {
    right: 1rem;
}

mark {
    background-color: #ffcece !important;
    border-radius: 3px;
}

.activeTab {
    color: var(--ion-color-3);
    background: var(--ion-color-2);
    border-radius: 6px;
}

.button-selected {
    color: #fca311;
}

video {
    max-height: 90vh !important;
    max-width: 90vw !important;
}

.searchbar-input.sc-ion-searchbar-md {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: 55px;
    padding-inline-start: 45px;
    -webkit-padding-end: 55px;
    padding-inline-end: 15px;
}

ion-slides {
    --bullet-background: grey;
}

.size-30 {
    height: 30px !important;
    width: 30px !important;
}

.size-40 {
    height: 40px !important;
    width: 40px !important;
}

.size-50 {
    height: 50px !important;
    width: 50px !important;
}

.size-105 {
    height: 105px !important;
    width: 105px !important;
}

p.heading {
    text-align: center !important;
    font-size: x-large !important;
    font-weight: 200 !important;
}

.bg-color-1 {
    background: var(--ion-color-1) !important;
    --background: var(--ion-color-1) !important;
}

.bg-color-2 {
    background: var(--ion-color-2) !important;
    --background: var(--ion-color-2) !important;
}

.bg-color-3 {
    background: var(--ion-color-3) !important;
    --background: var(--ion-color-3) !important;
}

.bg-color-4 {
    background: var(--ion-color-4) !important;
    --background: var(--ion-color-4) !important;
}

.bg-color-5 {
    // background: var(--ion-color-5) !important;
    --background: var(--ion-color-5) !important;
}

.color-1 {
    color: var(--ion-color-1) !important;
    --color: var(--ion-color-1) !important;
}

.color-2 {
    color: var(--ion-color-2) !important;
    --color: var(--ion-color-2) !important;
}

.color-3 {
    color: var(--ion-color-3) !important;
    --color: var(--ion-color-3) !important;
}

.color-4 {
    color: var(--ion-color-4) !important;
    --color: var(--ion-color-4) !important;
}

.color-5 {
    color: var(--ion-color-5) !important;
    --color: var(--ion-color-5) !important;
}

.badge {
    fill: var(--ion-color-1);
    // height: 20px;
    height: 16px;
    margin: 0 2px;
}

.p-top-3 {
    padding-top: 3rem !important;
}

app-verify-badge {
    height: 15px !important;
    // display: flex;
}

.popover-content {
    overflow: hidden !important;
}

.empty-screen-msg {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: 90%;
    text-align: center;
    // z-index: -1;
}

img.empty-screen-img {
    max-height: 10rem;
    display: block;
    margin: 3rem auto;
}

.show-healable-text-for-web {
    display: none;
}

@media screen and (min-width:800px) {

    .left,
    .right {
        font-size: 4rem !important;
        padding-bottom: 0px !important;
        padding: 1rem;
    }

    .show-healable-text-for-web {
        display: block;
    }
}

@media screen and (min-width:530px) {
    .m-top-3 {
        margin-top: 0 !important;
    }

    .show-healable-text-for-web {
        display: block;
    }
}

@media screen and (min-width:530px) {
    .p-top-3 {
        padding-top: 0 !important;
    }
}

.swiper-button-next,
.swiper-button-prev {
    width: calc(var(--swiper-navigation-size) / 50* 50);
    color: #000000a6;
    background: rgb(238 240 233 / 44%);
    border-radius: 35px;
    padding: 10px 10px;
}

:root {
    --swiper-navigation-color: #003b57;
}

@media screen and (min-width:800px) {
    .group-media-modal .modal-wrapper {
        width: 90vw;
        height: 90vh;
    }

    .group-media-modal .modal-wrapper video {
        width: 72vw;
    }
}